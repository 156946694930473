import sprite from 'feather-icons/dist/feather-sprite.svg'
import { twMerge } from 'tailwind-merge'
import type icons from 'feather-icons/dist/icons.json'

export type IconName = keyof typeof icons

type IconProps = {
  name: IconName
  title?: string
} & React.SVGAttributes<SVGElement>



export function Icon(props: IconProps) {
  const {
    name,
    className,
    title,
    ...rest
  } = props

  return (
    <>
      {title && <span className='sr-only'>{title}</span>}
      <svg
        className={twMerge('inline-block size-4 forced-colors:stroke-[CanvasText]', className)}
        fill='none'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        aria-hidden='true'
        focusable='false'
        {...rest}>
        <use href={`${sprite}#${name}`} />
      </svg>
    </>
  )
}