export const input = `
  block
  relative
  w-full

  shadow-sm

  border-0
  rounded-lg
  ring-1
  ring-inset
  ring-gray-300
  placeholder:text-gray-500

  focus-within:ring-2
  focus-within:ring-blue-500

  forced-colors:mt-[1px]
  forced-colors:border-1
  forced-colors:focus-within:outline-2
`

export const inputMd = `
  py-4
  px-5
`

export const TextInput = styled.input([
  input,
  inputMd,
])