export const Buttons = styled.div(`
  flex
  flex-wrap
  items-center
  gap-3
  mt-4
`)


export const buttonBase = `
  button
  inline-flex
  items-center
  justify-center
  relative
  font-semibold
  forced-colors:border-1

  cursor-pointer
  select-none
  text-balanced

  disabled:text-gray-400
  disabled:ring-gray-300
  disabled:bg-gray-50
  disabled:pointer-events-none
  [&:has(:focus-visible)]:outline
  [&:has(:focus-visible)]:outline-2
  [&:has(:focus-visible)]:outline-offset-2
  [&:has(:focus-visible)]:outline-blue-500
`


export const buttonSizes = {
  round: `
    p-2
    rounded-full
    text-sm
  `,
  xs: `
    px-1
    py-1
    rounded
    text-xs
  `,
  sm: `
    px-2
    py-1
    rounded-xl
    text-sm
  `,
  md: `
    px-4
    py-2
    rounded-full
    text-sm
    md:text-base
  `,
  lg: `
    px-5
    py-3
    rounded-full
    text-base
  `,
}

export const buttonSecondary = `
  ring-1
  ring-inset
  shadow-sm
`

export const buttonBaseState = `
  bg-white
  hover:bg-gray-50
  group-hover/button:bg-gray-50
  text-gray-900
  ring-gray-300
`

export const buttonActiveState = `
  bg-white
  text-blue-500
  ring-blue-300
`

export const buttonDisabledState = `
  text-gray-400
  ring-gray-300
  bg-gray-50
  pointer-events-none
`

const label = `
  [&:has(:checked)]:ring-blue-500
  [&:has(:checked)]:ring-2
  [&:has(:checked)]:text-blue-500
  [&:has(:checked)]:hover:ring-blue-600
`


export type ButtonProps = {
  isDisabled?: boolean
  isActive?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'round'
}

export const Button = styled.button(({ isDisabled, isActive, size = 'md' }: ButtonProps) => [
  buttonBase,
  buttonSizes[size],
  buttonSecondary,
  { [buttonBaseState]: !isDisabled && !isActive },
  { [buttonActiveState]: !isDisabled && isActive },
  { [buttonDisabledState]: isDisabled },
])

export const ButtonLabel = styled(Button, { tagName: 'label' })
export const ButtonDiv = styled(Button, { tagName: 'div' })
export const ButtonLink = styled(Button, { tagName: 'a' })


export const ButtonText = styled.button(({ isDisabled, isActive, size = 'md' }: ButtonProps) => [
  buttonBase,
  buttonSizes[size],
  'p-0 font-normal',
  { 'hover:text-blue-500 group-hover/button:text-blue-500': !isDisabled && !isActive },
  { 'text-blue-500': !isDisabled && isActive },
  { 'text-gray-400 pointer-events-none': isDisabled },
])

export const ButtonPlain = styled.button(({ isDisabled, isActive, size = 'md' }: ButtonProps) => [
  buttonBase,
  buttonSizes[size],
  { [buttonBaseState]: !isDisabled && !isActive },
  { [buttonActiveState]: !isDisabled && isActive },
  { [buttonDisabledState]: isDisabled },
])


export const ButtonChoice = styled.label<ButtonProps>(({ isDisabled, isActive, size = 'md' }: ButtonProps) => [
  buttonBase,
  buttonSizes[size],
  buttonSecondary,
  label,
  { [buttonBaseState]: !isDisabled && !isActive },
  { [buttonActiveState]: !isDisabled && isActive },
  { [buttonDisabledState]: isDisabled },
  // [&:has(:checked)]:ring-blue-500
  // [&:has(:checked)]:text-blue-500
  // [&:has(:checked)]:hover:text-blue-600
  `
  group
  `,
])

export const ButtonCheckbox = styled.div(`
  flex
  items-center
  justify-center

  w-6
  h-6
  -ml-2
  mr-2
  
  border
  border-gray-300
  border-dashed
  rounded-full
  shadow-sm

  group-has-[:checked]:border-blue-500
  group-has-[:checked]:border-solid
`)


export const ButtonPrimary = styled.button(({ size = 'md' }: ButtonProps) => ({
  className: [
    buttonBase,
    buttonSizes[size],
    `
    shadow-sm
    text-white
    bg-rose-500
    hover:bg-rose-600
    `,
  ],

  style: {
    textShadow: '0 0 9px 2px var(--color-rose-800)',
  },
}))

export const ButtonBlue = styled.button(({ size = 'md' }: ButtonProps) => ({
  className: [
    buttonBase,
    buttonSizes[size],
    `
    shadow-sm
    text-white
    bg-blue-500
    hover:bg-blue-600
    disabled:opacity-50
    disabled:text-white
    disabled:ring-0
    disabled:bg-blue-500
    disabled:pointer-events-none
    `,
  ],

  style: {
    textShadow: '0 0 9px 2px var(--color-blue-800)',
  },
}))